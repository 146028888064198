import axios from 'axios'


const settings = {
    baseURL: 'https://kaleici-org-default-rtdb.europe-west1.firebasedatabase.app/'
}
const instance = axios.create(settings)


export const api = {
    getData() {
        return instance.get<any>('.json')
    }
}

export interface DataType {
    date: string
    fieldServiceMeeting: {link: string, number: string, password: string}
    meeting: {link: string, number: string, password: string}
}
