import React, {FC}  from 'react'
import './AppButton.scss'

interface AppButtonType {
    onClick?: () => void
    mode: 'coral' | 'green' | 'blue' | 'black'
    children?: any
    disabled?: boolean
    className?: string
}

export const AppButton: FC<AppButtonType> = React.memo(({onClick, mode, children, disabled, className = ''}) => {

   const buttonModesStyles = () => {
      if (mode?.includes('coral')) return `button button_coral ${disabled && 'disabled'} ${className}`
      if (mode?.includes('green')) return `button button_green ${disabled && 'disabled'} ${className}`
      if (mode?.includes('blue')) return `button  button_blue ${disabled && 'disabled'}  ${className}`
      if (mode?.includes('black')) return `button button_black ${disabled && 'disabled'} ${className}`
   }

   return (
      <button
         className={buttonModesStyles()}
         onClick={onClick}
         disabled={disabled}
      >
         {children}
      </button>
   )
})

