import React, {useEffect} from 'react';
import {Route, Routes} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from '../bll/store';
import {Loading} from './components/loading/Loading';
import {ErrorPage} from './pages/error/ErrorPage';
import Home from './pages/home/Home';
import {fetchData} from '../bll/reducer/appSlice';
import '../assets/styles/app.scss'

const App = () => {
    const dispatch = useAppDispatch()
    const {isInitializeApp} = useAppSelector(state => state.app)

    useEffect(() => {
        dispatch(fetchData())
    }, [])

    if (!isInitializeApp) {
        return (
            <Loading/>
        )
    }

    return (
        <div className="wrapper">
            <Routes>
                <Route path={'/'} element={<Home/>}/>
                <Route path={'*'} element={<ErrorPage/>}/>
            </Routes>
        </div>
    );
}

export default App;


