import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

import {RootState} from '../store'
import {api, DataType} from '../../api/api';
import {AxiosError} from 'axios';
import {handleAsyncServerNetworkError} from '../../utils/error-utils';


export const fetchData = createAsyncThunk('fetchData/fetchDataTC', async (param, thunkAPI) => {
    thunkAPI.dispatch(setAppStatus({status: 'loading'}))
    try {
        const res = await api.getData()
        thunkAPI.dispatch(setAppStatus({status: 'success'}))
        return res.data
    } catch (err) {
        const error = err as AxiosError
        return handleAsyncServerNetworkError(error, thunkAPI)
    }
})


const initialState = {
    data: [] as DataType[],
    isInitializeApp: 'idle' as RequestStatusType,
    appThemeType: 'light' as AppThemeType,
    error: null as string | null,
    alertMessage: null as string | null
}


export const appSlice = createSlice({
    name: 'fetchData',
    initialState,
    reducers: {
        setAppStatus(state, action) {
            state.isInitializeApp = action.payload.status
        },
        setAppAlertMessage(state, action) {
            state.alertMessage = action.payload
        },
        setAppError(state, action) {
            state.error = action.payload.error
        },
        setAppThemeType(state, action) {
            state.appThemeType = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchData.fulfilled, (state, action) => {
                state.data = action.payload
            })
    }
})


export const selectApp = (state: RootState) => state.app

// Action creators are generated for each case reducer function
export const {setAppStatus, setAppError, setAppAlertMessage} = appSlice.actions

export default appSlice.reducer


export type RequestStatusType = 'idle' | 'loading' | 'success' | 'failed'
export type AppThemeType = 'light' | 'dark'
