import { configureStore, ThunkDispatch } from '@reduxjs/toolkit'
import thunkMiddleware from 'redux-thunk'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import { rootReducer } from './reducer'


export type RootReducerType = typeof rootReducer

export const store = configureStore({
   reducer: rootReducer,
   middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}).prepend(thunkMiddleware)
})




export type RootState = ReturnType<typeof store.getState>

export type AppRootStateType = ReturnType<RootReducerType>
export const useAppSelector: TypedUseSelectorHook<AppRootStateType> = useSelector
export type AppActionsType = any
export type AppDispatch = ThunkDispatch<AppRootStateType, unknown, AppActionsType>
export const useAppDispatch = () => useDispatch<AppDispatch>()

// @ts-ignore
window.store = store
