import React, {useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../bll/store';
import moment from 'moment'
import './Home.scss'
import {AppButton} from '../../components/AppButton/AppButton';
import {setAppAlertMessage} from '../../../bll/reducer/appSlice';
import SvgSelector from '../../SvgSelector/SvgSelector';
import Tilt from 'react-parallax-tilt';
import {DataType} from '../../../api/api';

// @ts-ignore
import meetings from '../../../assets/img/11.svg'
// @ts-ignore
import fieldServiceMeeting from '../../../assets/img/13.svg'


const Home = () => {
    const {data, alertMessage} = useAppSelector(state => state.app)
    const dispatch = useAppDispatch()
    const transitionSpeedTilt = 9000
    const tiltReverse = true

    const [copyStyle, setCopyStyle] = useState('');

    const currentDate = moment().startOf('isoWeek').format('DD.MM.YYYY')

    const filterDate = data.filter(elem => elem?.date === currentDate)[0]

    const dataUi: DataType = {
        date: filterDate?.date,
        fieldServiceMeeting: {
            link: filterDate?.fieldServiceMeeting.link,
            number: filterDate?.fieldServiceMeeting.link.slice(18, 29),
            password: filterDate?.fieldServiceMeeting?.password
        },
        meeting: {
            link: filterDate?.meeting.link,
            number: filterDate?.meeting.link.slice(18, 29),
            password: filterDate?.meeting.password
        }
    }

    const onClickTextHandler = (value: string) => {
        setCopyStyle(value)
        navigator.clipboard.writeText(value)
        dispatch(setAppAlertMessage('Скопировано'))
        setTimeout(() => {
            dispatch(setAppAlertMessage(''))
            setCopyStyle('')
        }, 2200)
    }

    return (
        <div className={'home'}>
            <div className={'home__container'}>
                <header className={'home__header'}>
                    <h1 className={'home__header_title'}>Подключение к встрече</h1>
                    <div className={'line-separator__horizon'}></div>
                </header>

                <main className={'home__main'}>
                    <Tilt className={'tilt__effect'} tiltMaxAngleX={9} tiltMaxAngleY={9} reset={true}
                          transitionSpeed={transitionSpeedTilt} tiltReverse={tiltReverse}>
                        <div className={'block'}>
                            <h2 className={'block__title'}><img className={'block__title-icon'} src={meetings} alt=""/> Встреча для проповеди</h2>
                            <div className={'block__columns'}>
                                <div className={'column-titles'}>
                                    <div className={'column-titles__item'}>
                                        Неделя от
                                    </div>
                                    <div className={'column-titles__item'}>
                                        Zoom
                                    </div>
                                    <div className={'column-titles__item'}
                                         onClick={() => onClickTextHandler(dataUi.fieldServiceMeeting.password)}>
                                        Пароль
                                    </div>
                                    <div className={'column-titles__item'}
                                         onClick={() => onClickTextHandler(dataUi.fieldServiceMeeting.number)}>
                                        Номер
                                    </div>
                                </div>
                                <div className={'line-separator__vertical'}></div>
                                <div className={'column-data'}>
                                    <div className={'column-data__item'}>
                                        {dataUi.date}
                                    </div>
                                    <div className={'column-data__item column-data__item_button'}>
                                        <a href={dataUi.fieldServiceMeeting.link} target={'_blank'}>
                                            <AppButton mode={'black'}>Подключиться</AppButton>
                                        </a>
                                    </div>
                                    <div onClick={() => onClickTextHandler(dataUi.fieldServiceMeeting.password)}
                                         className={copyStyle === dataUi.fieldServiceMeeting.password ? 'column-data__item copy not-allowed' : 'column-data__item pointer'}>
                                        {dataUi.fieldServiceMeeting.password}
                                        <span
                                            className={copyStyle === dataUi.fieldServiceMeeting.password ? 'copy-icon disabled' : 'copy-icon'}><SvgSelector
                                            id={'copy'}/></span>
                                    </div>
                                    <div onClick={() => onClickTextHandler(dataUi.fieldServiceMeeting.number)}
                                         className={copyStyle === dataUi.fieldServiceMeeting.number ? 'column-data__item copy not-allowed' : 'column-data__item pointer'}>
                                        {dataUi.fieldServiceMeeting.number}
                                        <span
                                            className={copyStyle === dataUi.fieldServiceMeeting.number ? 'copy-icon disabled' : 'copy-icon'}><SvgSelector
                                            id={'copy'}/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tilt>
                    <Tilt className={'tilt__effect'} tiltMaxAngleX={9} tiltMaxAngleY={9} reset={true}
                          transitionSpeed={transitionSpeedTilt} tiltReverse={tiltReverse}>
                        <div className={'block'}>
                            <h2 className={'block__title'}><img src={fieldServiceMeeting} alt=""/> Встреча собрания</h2>
                            <div className={'block__columns'}>
                                <div className={'column-titles'}>
                                    <div className={'column-titles__item'}>
                                        Неделя от
                                    </div>
                                    <div className={'column-titles__item'}>
                                        Zoom
                                    </div>
                                    <div className={'column-titles__item'}
                                         onClick={() => onClickTextHandler(dataUi.meeting.password)}>
                                        Пароль
                                    </div>
                                    <div className={'column-titles__item'}
                                         onClick={() => onClickTextHandler(dataUi.meeting.number)}>
                                        Номер
                                    </div>
                                </div>
                                <div className={'line-separator__vertical'}></div>
                                <div className={'column-data'}>
                                    <div className={'column-data__item'}>
                                        {dataUi.date}
                                    </div>
                                    <div className={'column-data__item column-data__item_button'}>
                                        <a href={dataUi.meeting.link} target={'_blank'}>
                                            <AppButton mode={'black'}>Подключиться</AppButton>
                                        </a>
                                    </div>
                                    <div onClick={() => onClickTextHandler(dataUi.meeting.password)}
                                         className={copyStyle === dataUi.meeting.password ? 'column-data__item copy not-allowed' : 'column-data__item pointer'}>
                                        {dataUi.meeting.password}
                                        <span
                                            className={copyStyle === dataUi.meeting.password ? 'copy-icon disabled' : 'copy-icon'}><SvgSelector
                                            id={'copy'}/></span>
                                    </div>
                                    <div onClick={() => onClickTextHandler(dataUi.meeting.number)}
                                         className={copyStyle === dataUi.meeting.number ? 'column-data__item copy not-allowed' : 'column-data__item pointer'}>
                                        {dataUi.meeting.number}
                                        <span
                                            className={copyStyle === dataUi.meeting.number ? 'copy-icon disabled' : 'copy-icon'}><SvgSelector
                                            id={'copy'}/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tilt>
                </main>
            </div>
            {alertMessage && <div
                className={alertMessage ? 'alert-message alert-message__info' : 'alert-message'}>{alertMessage}</div>}
        </div>
    )
}

export default Home;
