import React from 'react';
import './Loading.scss'

export const Loading = () => {
    return (
        <div className={'loading-wrap'}>
            <div className={'loading'}>
                <div className="loader"></div>
            </div>
        </div>
    );
};

